import React from 'react';
import Home from "./pages/Home";
// import logo from './logo.svg';
// import './App.scss';

function App() {
  return (
    <Home />
  );
}

export default App;
